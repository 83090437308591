<template>
  <label @click="isCheck = !isCheck" class="flex items-center gap-3 cursor-pointer">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        :fill="isCheck ? activeFill : defaultFill"
      />
      <path
        d="M12 17.25C14.8995 17.25 17.25 14.8995 17.25 12C17.25 9.10051 14.8995 6.75 12 6.75C9.10051 6.75 6.75 9.10051 6.75 12C6.75 14.8995 9.10051 17.25 12 17.25Z"
        :fill="isCheck ? activeFill : 'transparent'"
      />
    </svg>
    <span v-if="title" class="capitalize text-sm text-font-3 mb-0">{{ title }}</span>
  </label>
</template>

<script lang="ts" setup>
defineProps({
  title: {
    type: String,
    default: "",
  },
  defaultFill: {
    type: String,
    default: "var(--border_color7)",
  },
  activeFill: {
    type: String,
    default: "var(--text_dark2)",
  },
});
const isCheck = ref(false);
</script>

<style></style>
